<div class="min-h-screen flex flex-col bg-gradient-to-b from-purple-100 to-pink-100">
    <!-- Top Bar -->
    <div class="bg-purple-900 text-white py-2 px-4 shadow-md">
        <div class="max-w-6xl mx-auto flex justify-between items-center">
            <h1 class="text-xl font-bold">OpenTest VAIRE</h1>
            <span>Validation - Assess - Interpret - Review - Evaluate</span>
            <div class="flex items-center space-x-4">
                <div class="flex items-center space-x-2">
                    <img [src]="userAvatarUrl" alt="User Avatar" class="w-8 h-8 rounded-full border-2 border-white">
                    <span class="text-sm font-medium">{{ username }}</span>
                </div>
                <button (click)="logout()"
                    class="bg-pink-500 text-white px-3 py-1 rounded-full text-sm hover:bg-pink-600 transition duration-300">
                    Logout
                </button>
            </div>
        </div>
    </div>

    <main class="max-w-3xl w-full mx-auto px-4 py-8">
        <div class="bg-white shadow-lg rounded-lg p-6">
            <h2 class="text-pink-600 uppercase font-semibold text-sm mb-2">EVALUATE YOUR API TESTS</h2>
            <h1 class="text-2xl font-bold text-purple-900 mb-4">
                Visualize and Interpret<br />Your API Test Results
            </h1>
            <p class="text-sm text-gray-700 mb-6">
                Paste your JSON test results below to get a comprehensive evaluation of your API's performance and
                reliability.
            </p>

            <div class="mb-6">
                <textarea
                    class="w-full h-32 p-3 text-sm border rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                    [(ngModel)]="jsonInput" placeholder="Paste your JSON test results here..."></textarea>
                <div class="mt-3 flex space-x-3">
                    <button
                        class="bg-pink-500 text-white px-4 py-2 text-sm rounded-full hover:bg-pink-600 transition duration-300 ease-in-out"
                        (click)="handleSubmit()">
                        EVALUATE RESULTS
                    </button>
                    <button
                        class="border border-pink-500 text-pink-500 px-4 py-2 text-sm rounded-full hover:bg-pink-50 transition duration-300 ease-in-out flex items-center">
                        VIEW SAMPLE
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 ml-1" viewBox="0 0 20 20"
                            fill="currentColor">
                            <path fill-rule="evenodd"
                                d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z"
                                clip-rule="evenodd" />
                        </svg>
                    </button>
                </div>
            </div>

            <div *ngIf="error" class="mb-6 bg-red-100 border-l-4 border-red-500 text-red-700 p-3 text-sm" role="alert">
                <p class="font-bold">Error</p>
                <p>{{ error }}</p>
            </div>

            <div *ngIf="parsedData" class="mt-6">
                <div class="grid grid-cols-3 gap-3 mb-6">
                    <div class="bg-purple-50 rounded-lg p-3">
                        <h3 class="text-purple-800 font-semibold text-sm mb-1">Total Tests</h3>
                        <span class="text-2xl font-bold text-purple-600">{{ testSummary.total }}</span>
                    </div>
                    <div class="bg-green-50 rounded-lg p-3">
                        <h3 class="text-green-800 font-semibold text-sm mb-1">Passed Tests</h3>
                        <span class="text-2xl font-bold text-green-600">{{ testSummary.passed }}</span>
                    </div>
                    <div class="bg-red-50 rounded-lg p-3">
                        <h3 class="text-red-800 font-semibold text-sm mb-1">Failed Tests</h3>
                        <span class="text-2xl font-bold text-red-600">{{ testSummary.failed }}</span>
                    </div>
                </div>

                <h2 class="text-lg font-bold text-purple-900 mb-3">Detailed Test Results</h2>
                <div *ngFor="let test of parsedData; let i = index" class="mb-3 bg-gray-50 rounded-lg overflow-hidden">
                    <div class="cursor-pointer flex flex-row items-center justify-between p-3"
                        [class.bg-green-50]="isTestPassed(test)" [class.bg-red-50]="!isTestPassed(test)"
                        (click)="toggleTestExpansion(i)">
                        <div class="flex items-center">
                            <svg *ngIf="isTestPassed(test)" xmlns="http://www.w3.org/2000/svg"
                                class="h-4 w-4 text-green-500 mr-2" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd"
                                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                    clip-rule="evenodd" />
                            </svg>
                            <svg *ngIf="!isTestPassed(test)" xmlns="http://www.w3.org/2000/svg"
                                class="h-4 w-4 text-red-500 mr-2" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd"
                                    d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                                    clip-rule="evenodd" />
                            </svg>
                            <h3 class="text-sm font-semibold" [class.text-green-800]="isTestPassed(test)"
                                [class.text-red-800]="!isTestPassed(test)">
                                {{ test.name }}
                            </h3>
                        </div>
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd"
                                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                clip-rule="evenodd" />
                        </svg>
                    </div>
                    <div *ngIf="isTestExpanded(i)" class="p-3">
                        <p class="text-xs text-gray-600 mb-2">{{ test.description }}</p>
                        <div *ngFor="let error of test.errors" class="mb-2 p-2 bg-white rounded text-xs">
                            <p class="font-medium text-purple-800">{{ error.test }}</p>
                            <p>
                                Valid: <span [class.text-green-500]="error.valid" [class.text-red-500]="!error.valid">
                                    {{ error.valid ? 'Yes' : 'No' }}
                                </span>
                            </p>
                            <ng-container *ngIf="!error.valid">
                                <p class="mt-1">
                                    <span class="font-medium">Expected:</span> {{ error.expected | json }}
                                </p>
                                <p class="mt-1">
                                    <span class="font-medium">Received:</span> {{ error.received | json }}
                                </p>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>