import { Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { CodeAnalyserComponent } from './components/code-analyser/code-analyser.component';
import { FailedComponent } from './components/failed/failed.component';

export const routes: Routes = [
    {
        path: '',
        component: CodeAnalyserComponent,
        canActivate: [MsalGuard],
    },
    {
        path: 'login-failed',
        component: FailedComponent,
    },
    {
        path: '**',
        redirectTo: '',
    },
];
