<div class="min-h-screen bg-gradient-to-b from-purple-100 to-pink-100 flex flex-col items-center justify-center p-4">
    <div class="max-w-md w-full bg-white shadow-lg rounded-lg overflow-hidden">
      <div class="bg-red-100 border-b border-red-200 px-4 py-3">
        <h2 class="text-lg font-semibold text-red-800">Login Failed</h2>
      </div>
      <div class="p-4">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12 text-red-500 mx-auto mb-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
        </svg>
        
        <p class="text-sm text-gray-700 mb-4">We encountered an error while trying to log you in. This could be due to network issues or invalid credentials.</p>
        
        <div class="space-y-2">
          <button
            (click)="retryLogin()"
            class="w-full bg-pink-500 text-white px-4 py-2 rounded-full hover:bg-pink-600 transition duration-300 ease-in-out text-sm"
          >
            Retry Login
          </button>
        </div>
      </div>
    </div>
  </div>