import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, inject, Inject, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router, RouterOutlet } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { filter, map, Observable, pipe, switchMap, tap } from 'rxjs';

interface TestError {
  test: string;
  valid: boolean;
  expected: any;
  received: any;
}

interface Test {
  name: string;
  description: string;
  errors: TestError[];
}
type ProfileType = {
  displayName?: string,
  givenName?: string,
  surname?: string,
  userPrincipalName?: string,
  id?: string
};

@Component({
  selector: 'app-code-analyser',
  standalone: true,
  imports: [RouterOutlet, CommonModule, FormsModule],
  templateUrl: './code-analyser.component.html',
  styleUrl: './code-analyser.component.scss'
})
export class CodeAnalyserComponent implements OnInit {
  jsonInput = '';
  parsedData: Test[] | null = null;
  error: string | null = null;
  expandedTests: boolean[] = [];
  username: string = 'User';
  userAvatarUrl: string = 'https://via.placeholder.com/150';

  private authService: MsalService = inject(MsalService);
  private router = inject(Router);
  private client: HttpClient = inject(HttpClient);
  private msalBroadcastService: MsalBroadcastService = inject(MsalBroadcastService);

  ngOnInit(): void {
    this.client.get('https://graph.microsoft.com/v1.0/me').pipe(
      tap((user: ProfileType) => this.username = user.displayName ?? user.userPrincipalName ?? 'User'),
      switchMap(() => this.getUserAvatar())
    ).subscribe(
      url => this.userAvatarUrl = url
    );

    this.msalBroadcastService.msalSubject$
      .pipe(
        tap((msg: EventMessage) => console.log(msg)),
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS)
      )
      .subscribe((result: EventMessage) => {
        console.log(result);
        const payload = result.payload as AuthenticationResult;
        this.authService.instance.setActiveAccount(payload.account);
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None)
      )
      .subscribe(() => {
      });
  }


  get testSummary() {
    if (!this.parsedData) return { total: 0, passed: 0, failed: 0 };
    const total = this.parsedData.length;
    const passed = this.parsedData.filter(test => this.isTestPassed(test)).length;
    return { total, passed, failed: total - passed };
  }

  handleSubmit() {
    try {
      this.parsedData = JSON.parse(this.jsonInput);
      this.error = null;
      this.expandedTests = new Array(this.parsedData!.length).fill(false);
    } catch (err) {
      this.error = 'Invalid JSON input. Please check your data and try again.';
      this.parsedData = null;
    }
  }

  toggleTestExpansion(index: number) {
    this.expandedTests[index] = !this.expandedTests[index];
  }

  isTestPassed(test: Test): boolean {
    return test.errors.every(error => error.valid);
  }

  isTestExpanded(index: number): boolean {
    return this.expandedTests[index];
  }

  getUserAvatar(): Observable<string> {
    return this.client.get(`https://graph.microsoft.com/v1.0/me/photo/$value`, {
      responseType: 'blob'
    }).pipe(
      map(blob => URL.createObjectURL(blob))
    );
  }

  logout() {
    this.authService.logout().subscribe(() => {
      this.router.navigate(['/']);
    });
  }


}
