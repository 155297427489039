import { Component, Inject } from '@angular/core';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-failed',
  standalone: true,
  imports: [],
  templateUrl: './failed.component.html',
  styleUrl: './failed.component.scss'
})
export class FailedComponent {

  private authService: MsalService = Inject(MsalService);

  retryLogin() {
    this.authService.loginRedirect();
  }
}